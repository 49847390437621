<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddAppShow">新建</el-button>
      </div>
    </div>
    <el-table border v-bind:data="list">
      <el-table-column label="名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="EditAppShow(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="DeleteAppShow(scope.row)">删除</el-button>
          <i style="font-weight: 700" class="el-icon-top sort" @click="AppShowSort(scope.row,2)"></i>
          <i style="font-weight: 700" class="el-icon-bottom sort" @click="AppShowSort(scope.row,1)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog :title="title+'APP展示'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="类目集合" prop="" label-width="100px">
          <el-select v-model="form.category_code" multiple>
            <el-option
              v-for="item in categorylist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目录名称" prop="name" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上级目录" prop="" label-width="100px">
          <el-select v-model="form.pcode">
            <el-option
              v-for="item in pcodelist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Addconfirm)">{{edit?'修改':'新增'}}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        app_code: ''
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      edit: false,
      form: {
        code: '',
        category_code: '',
        name: '',
        pcode: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
      categorylist: [],
      pcodelist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetCategory () {
      this.api.CategoryList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.categorylist = res.data.data.data
          this.GetPcodeList()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetPcodeList () {
      this.api.ClientAppShowList({
        page: 1,
        limit: 100,
        app_code: this.search.app_code
      }).then(res => {
        if (res.data.code == 200) {
          this.pcodelist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.ClientAppShowList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Addconfirm () {
      this.api.ClientAppShowUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title+ '失败' + res.data.msg)
      })
    },
    AddAppShow () {
      this.edit = false
      this.title = '创建'
      this.dialogFormVisible = true
    },
    EditAppShow (item) {
      this.edit = true
      this.title = '编辑'
      this.api.ClientAppShowDetail({code: item.code}).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          this.form.category_code = JSON.parse(res.data.data.category_code)
          this.dialogFormVisible = true
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    DeleteAppShow (item) {
      this.$confirm('是否删除该app展示?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ClientAppShowDelete({code: item.code}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    AppShowSort (item,action) {
      this.api.ClientAppShowOrder({
        code: item.code,
        action: action
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('更改排序成功')
          this.Search()
          return
        }
        this.$message.error('更改排序失败' + res.data.msg)
      })
    },
    goback (){
      this.$router.push('/iot/app/appvesion')
    },
  },
  filters: {},
  mounted () {
    this.search.app_code = this.$route.params.code
    this.Search()
  },
  created () {
    this.GetCategory()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.sort {
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 700px!important;
}
</style>
